<template>
  <iframe src="https://vedlys.vpt.lt/report.html"></iframe>
</template>
<script>

export default {
  name: 'server_stats',
  components: {
  },
  setup() {
    return {
    }
  },
  methods: {
  }
}
</script>

<style scoped>
iframe {
  display: block;       /* iframes are inline by default */
  border: none;         /* Reset default border */
  height: 100vh;        /* Viewport-relative units */
  width: 100%;
}
</style>
